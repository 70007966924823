/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  // {
  //   url: '/acp',
  //   name: 'dashboard',
  //   slug: 'dashboard',
  //   icon: 'HomeIcon'
  // },

  {
    url: '/acp/cashiers',
    name: 'Cashiers',
    slug: 'Cashiers',
    icon: 'LayersIcon'
  },
  {
    url: '/acp/Accounts',
    name: 'Accounts',
    slug: 'Account',
    icon: 'UsersIcon'
  },
  {
    url: '/acp/users',
    name: 'Users',
    slug: 'Users',
    icon: 'UserIcon'
  },
  // {
  //   url: '/acp/roles',
  //   name: 'Roles',
  //   slug: 'Roles',
  //   icon: 'KeyIcon'
  // },


  {
    url: '/acp/transaction_categories',
    name: 'TransactionCategories',
    slug: 'Transaction Categories',
    icon: 'AlignCenterIcon'
  }
]

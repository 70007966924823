<template>
<div>
 <img :src="url" width="200">
 <hr style="height:0px;border-width:0;color:gray;background-color:gray"/>
</div>
</template>
<script>
import image from '../../../public/logo.png'
export default {
  data () {
    return {
      url:image
    }
  }
}
</script>

<template>
  <div class="the-navbar__user-meta flex items-center">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.name }}</p>
      <small>{{ $t(activeUserInfo.status) }}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <img key="onlineImg"
         :src="activeUserInfo.avatar || 'https://media-exp1.licdn.com/dms/image/C510BAQHk1vWaa19NIA/company-logo_200_200/0/1519881256069?e=2159024400&v=beta&t=RGyHekmKQ5wGChYUHQIi4G5w9wQl3qIdkDNDLLIffBE'" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <li class="flex py-2 px-4 cursor-pointer hover:text-white ">
            <router-link
              :to="{ name: 'MyAccount'}"
            >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2"> {{$t('Profile')}} </span>
            </router-link>
          </li>
          <vs-divider class="m-1" />
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2"> {{$t('Logout')}} </span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
export default {
  computed: {
    activeUserInfo () {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    logout () {
      localStorage.removeItem('user')
      localStorage.removeItem('access_token')

      // This is just for demo Purpose. If user clicks on logout -> redirect
      this.$router.push('/login').catch(() => {})
    }
  }
}
</script>
